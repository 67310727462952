import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import AmazonContent from "src/components/amazoncontent"
import CancelModal from "src/components/cancelmodal"
import FooterLinks from "src/components/footerlinks"


const prdbestData = import("./data/data.json");

export default function prdbest() {
  return(
    <HomepageLayout>

    <Helmet>
      <style type="text/css">{`
      `}
      </style>
      <title>Is Amazon Actually Giving You The Best Price</title>
    </Helmet>

    <section>
      <AmazonContent data={prdbestData}></AmazonContent>
      <FooterLinks></FooterLinks>

      <CancelModal></CancelModal>
    </section>

    </HomepageLayout>
  )
}
